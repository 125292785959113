import {MarketPlaceCode} from '../marketplaces/types'

export enum CaptchaStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WAITING = 'waiting',
}

export enum MpConnectionStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  WAITING = 'waiting',
}

export type TMarketplace = {
  id: number
  code: MarketPlaceCode
  img: string
}

export type TStep = {
  title: string
  canSkip?: boolean
  skipCount?: number
  component: React.FC
}

export type TCompany = {
  id: number
  name: string
  address: string
  inn: string
  kpp: string
  bic: string
  ogrn?: string
  customer?: number
}

export type TCompanyInfo = {
  customerCompany: TCompany | null
  accId: number
}

export type TAccNames = {
  accId: number
  shDAccName: string
  accName: string
}

export type TYMAccount = {
  id: number
  domain: string
  clientId: string
  business: {
    id: number
    name: string
  }
}

export type TRegisterYMTokenRequest = {
  id: number
  validation_code: string
}

export type TGetCaptchaRequest = {
  phone: string
  isRegeneration: boolean
}

export interface IWizardState {
  data: any
  marketplace: TMarketplace | null
  stepHistory: number[]
  steps: TStep[]
  currentStep: number
  validations: {
    [key: string]: {
      [field: string]: boolean
    }
  }
  from: string
  isLoading: boolean
  isSuccessLastOperation: boolean
}

export const initialState: IWizardState = {
  data: {},
  from: 'default',
  marketplace: null,
  stepHistory: [],
  steps: [],
  currentStep: 0,
  validations: {},
  isLoading: false,
  isSuccessLastOperation: false,
}

export const mpWizardActionsTypes = {
  getCaptcha: '[GetCaptcha] Action',
  postCaptcha: '[PostCaptcha] Action',
  postCodePhone: '[PostCodePhone] Action',
  addCustomerCompany: '[AddCustomerCompanyFromWizard] Action',
  addAccount: '[AddAccountFromWizard] Action',
  getYMTokenLink: '[GetYMTokenLink] Action',
  registerYMToken: '[RegisterYMToken] Action',
  testConnection: '[TestConnection] Action',
}
