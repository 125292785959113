import {useState, ChangeEvent, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {updateData} from '../../../../../store'

import {Form} from 'react-bootstrap'

import {StorageType} from '../../../../../../setup'

type TPromotionTokens = {
  clientId: string
  apiSecret: string
}

const OzonStep2 = () => {
  const data = useSelector((storage: StorageType) => storage.mpWizard.data)

  const initDataTokens = {
    clientId: '',
    apiSecret: '',
  }

  const [tokens, setTokens] = useState<TPromotionTokens>(data?.step1 || initDataTokens)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!data?.step1) {
      dispatch(updateData({key: 'step1', field: 'clientId', value: ''}))
      dispatch(updateData({key: 'step1', field: 'apiSecret', value: ''}))
    }
  }, [])

  const updateTokens = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target

    dispatch(updateData({key: 'step1', field: name, value: value}))

    setTokens((prev) => ({...prev, [name]: value}))
  }

  return (
    <div className='fs-5'>
      <p className='mb-7'>
        Токен рекламного кабнета необходим для того, чтобы наш сервис мог взаимодействовать с вашим
        аккаунтом OZON и выполнять необходимые задачи, такие как автоматизация процессов, интеграция
        с вашим магазином и управление данными.
      </p>

      <div className='mb-9'>
        <Form.Label className='text-gray-700' htmlFor='clientId'>
          Client ID рекламного кабинета:
        </Form.Label>
        <Form.Control
          name='clientId'
          id='clientId'
          value={tokens.clientId}
          onChange={updateTokens}
          className='bg-secondary mb-6'
        />

        <Form.Label className='text-gray-700' htmlFor='apiSecret'>
          API SECRET рекламного кабинета:
        </Form.Label>
        <Form.Control
          id='apiSecret'
          name='apiSecret'
          value={tokens.apiSecret}
          onChange={updateTokens}
          className='bg-secondary'
        />
      </div>
      <p>
        Чтобы получить Client ID и API SECRET рекламного кабинета, необходимо перейти в личный
        кабинет на{' '}
        <a href='https://seller.ozon.ru/' target='_blank' rel='noopener noreferrer'>
          портале поставщика
        </a>{' '}
        OZON.
      </p>
      <p>
        Кабинет поставщика &gt; API ключи &gt; Performance API &gt; Создать аккаунт &gt; Добавить
        ключ &gt; Введите название &gt; Добавить{' '}
      </p>
      <p>Скопируйте Client ID и API SECRET из созданного сервисного аккаунта.</p>
      <p>
        Более подробно можно ознакомиться в{' '}
        <a
          href='https://docs.shop-delivery.ru/personal_account/#_8'
          target='_blank'
          rel='noopener noreferrer'
        >
          Руководстве пользователя
        </a>
        .{' '}
      </p>
    </div>
  )
}

export default OzonStep2
