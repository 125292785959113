import {useState, ChangeEvent} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateData, nextStep} from '../../../../../store'

import {Form, Button} from 'react-bootstrap'

import {StorageType} from '../../../../../../setup'
import get_ozon_cookie from '../../../../../../_metronic/assets/media/images/get_ozon_cookie.webp'

const OzonStep3 = () => {
  const data = useSelector((storage: StorageType) => storage.mpWizard.data)

  const [ozonCookie, setOzonCookie] = useState(data?.step2?.ozonCookie || '')

  const dispatch = useDispatch()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOzonCookie(e.target.value)

    dispatch(updateData({key: 'step2', field: 'ozonCookie', value: e.target.value}))
  }

  const onClick = () => {
    dispatch(nextStep())
  }

  return (
    <div>
      <p className='mb-8 fs-5 text-gray-700'>
        Пожалуйста, введите куки личного кабинета. Это необязательный шаг, но без него не будет
        возможности загрузить отзывы с Ozon в наш сервис.
      </p>
      <div className='mb-6' style={{maxWidth: '800px'}}>
        <img className='w-100' src={get_ozon_cookie} alt='get_ozon_cookie' />
      </div>

      <Form.Control
        name='authorization'
        value={ozonCookie}
        onChange={onChange}
        className='bg-secondary mb-9'
        placeholder='Куки личного кабинета'
      />

      <Button onClick={onClick} disabled={!ozonCookie}>
        Далее
      </Button>
    </div>
  )
}

export default OzonStep3
