import {IUrlParameters} from '../components/lists/UrlFilteredList'

const TOKEN_COOKIE = '_token'
const REFRESH_TOKEN_COOKIE = '_token-refresh'

export {TOKEN_COOKIE, REFRESH_TOKEN_COOKIE}

// timeout to typing. time between pressing keys
export const SEARCH_DEBOUNCE_TIMEOUT = 1000

export const DEFAULT_CHANGED_ID = -2

let resultType: 'direct' | 'data' | 'data.result' | 'data.results' | 'data.categories'
export type TSagaResultType = typeof resultType

export const emptyUrlParameters: IUrlParameters = {
  filter: '',
  itemsPerPage: 10,
  ordering: '',
  pageNumber: 0,
  url: '',
}

export interface IAdditionalPutActionWithParams {
  action: any
  params: any
}

export interface IAdditionalPutActionWithExtendedParams {
  action: any
  params?: any
  resultType?: TSagaResultType // from what object we get additional property
  resultTypePropertyName?: string // if we want to use results in params, we can set property name of data here
}
