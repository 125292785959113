import {useState, ChangeEvent, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateData} from '../../../../../store'
import {testConnectionAction} from '../../../../../store/mpWizard/actions'
import {useIntl} from 'react-intl'

import {Form, Button} from 'react-bootstrap'

import {MpConnectionStatus, IWizardState} from '../../../../../store/mpWizard/types'
import {StorageType} from '../../../../../../setup'

const WBStep1 = () => {
  const intl = useIntl()

  const [connectionStatus, setConnectionStatus] = useState(MpConnectionStatus.WAITING)

  const {marketplace, isLoading, currentStep, data}: IWizardState = useSelector(
    (storage: StorageType) => storage.mpWizard
  )

  const [authorizationToken, setAuthorizationToken] = useState(
    data?.[`step${currentStep}`]?.authorization || ''
  )

  const accessRights = data?.[`step${currentStep}`]?.accessRights

  const dispatch = useDispatch()

  useEffect(() => {
    if (accessRights) {
      if (accessRights.length > 0) {
        setConnectionStatus(MpConnectionStatus.SUCCESS)
      } else {
        setConnectionStatus(MpConnectionStatus.ERROR)
      }
    }
  }, [accessRights])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAuthorizationToken(e.target.value)

    dispatch(updateData({key: `step${currentStep}`, field: 'authorization', value: e.target.value}))
  }

  const onTestConnection = (authToken: string) => () => {
    setConnectionStatus(MpConnectionStatus.WAITING)

    dispatch(
      testConnectionAction({
        marketplaceId: marketplace?.id || -1,
        connectionParams: [{parameterName: 'api_key', value: authToken || ''}],
      })
    )
  }

  return (
    <div className='fs-5'>
      <p className='mb-9'>
        {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.DESCRIPTION1'})}
      </p>
      <div className='d-flex mb-9 align-items-start justify-content-between gap-5'>
        <div className='w-100'>
          <Form.Control
            name='authorization'
            value={authorizationToken}
            onChange={onChange}
            className='bg-secondary'
            placeholder='Токен авторизации'
          />
          {connectionStatus === MpConnectionStatus.SUCCESS && (
            <p className='text-success mb-0'>
              {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.CONNECTION.SUCCESS'})}
            </p>
          )}

          {connectionStatus === MpConnectionStatus.ERROR && (
            <p className='text-danger mb-0'>
              {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.CONNECTION.FAILURE'})}
            </p>
          )}
        </div>
        <Button
          className='text-nowrap'
          disabled={authorizationToken === '' || isLoading}
          onClick={onTestConnection(authorizationToken)}
        >
          {isLoading
            ? intl.formatMessage({id: 'ACTION.WAIT'})
            : intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.CONNECTION.CHECK'})}
        </Button>
      </div>
      <p>
        {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.DESCRIPTION2'})}{' '}
        <span className='fst-italic'>
          {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.DESCRIPTION3'})}
        </span>
      </p>
      <p>
        {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.DESCRIPTION4'})}{' '}
        <a
          href='https://seller.wildberries.ru/about-portal/ru/?redirect_url=https%3A%2F%2Fseller.wildberries.ru%2F'
          target='_blank'
          rel='noopener noreferrer'
        >
          {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.LINK.PORTAL'})}
        </a>{' '}
        на профиль &gt; Настройки &gt; Доступ к API
      </p>
      <p>{intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.DESCRIPTION5'})}</p>
      <p className='mb-0'>
        {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.DESCRIPTION6'})}
      </p>
      <ul>
        <li>
          {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.DESCRIPTION7'})}
        </li>
        <li>
          {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.DESCRIPTION8'})}
        </li>
        <li>
          {intl.formatMessage({id: 'COMPONENTS.MPWIZARD.MARKETPLACES.WB.STEP1.DESCRIPTION9'})}
        </li>
      </ul>
      <p>
        Более подробно можно ознакомиться в{' '}
        <a
          href='https://docs.shop-delivery.ru/personal_account/#_8'
          target='_blank'
          rel='noopener noreferrer'
        >
          Руководстве пользователя
        </a>
        .{' '}
      </p>
    </div>
  )
}

export default WBStep1
